import React from 'react'

export const SobreNosotros = () => {
    return (
        <div className='container'>
            <div className="row">
                <h1 className='mt-5'><b>PROWORXby420</b></h1>
            </div>   
            <div className="row">
                <div className="col-12 col-md-6">
                <h3>Historia</h3>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                        Ipsum, blanditiis facere enim quidem molestias temporibus non. 
                        Itaque saepe quas quisquam numquam iste id vero dolor inventore sapiente! 
                        Fugiat pariatur blanditiis tempora perferendis in earum excepturi numquam quaerat
                        beatae modi velit ipsa cupiditate ratione suscipit impedit esse quasi quo, odio 
                        eos quae error perspiciatis sequi. Quasi odio harum voluptatibus provident ab! Ullam,
                        facilis quia saepe dolorem, amet vero suscipit debitis ratione qui ea minus facere beatae?
                        Impedit blanditiis quos a nisi, ut earum sed debitis cupiditate illo aut, qui repudiandae, 
                        sit explicabo aliquam eius necessitatibus. Corrupti architecto assumenda.</p> 
                        <p>ratione ex dolores quam id pariatur. Iure, numquam praesentium laborum ipsam aut perspiciatis distinctio 
                        nemo earum qui architecto doloribus. Eveniet quaerat facere obcaecati distinctio consequatur possimus 
                        minima ut adipisci tempora placeat sequi dolorum impedit nesciunt, itaque non! Voluptate 
                        impedit ea modi reiciendis officiis dolor laudantium vel magni illum iure consequatur cupiditate 
                        nostrum, ipsa excepturi sapiente sed eos esse distinctio suscipit at deleniti! Earum, perspiciatis 
                        recusandae eveniet, ut quia perferendis obcaecati odit fugiat libero architecto quas! Dolorem at adipisci 
                        maiores amet porro ut unde.
                    </p>
                </div>
                <div className="col-12 col-md-6">
                <div id="carouselExampleSlidesOnly" className="carousel slide mt-5" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                            <img classname="imgNosotrosCarousell" src="https://scontent.fslw1-1.fna.fbcdn.net/v/t39.30808-6/309322358_494686709336270_8974588118009266130_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEGdsEtvqa5GIRNb8ArXLt9Wp3oUP1XLl1anehQ_VcuXSs_93QAWLHxKjRABUybPdjWwgG7qLezoWhwqwNY4xkH&_nc_ohc=BqEh0ieSgVwAX8lwt3Y&_nc_ht=scontent.fslw1-1.fna&oh=00_AfD8kuGr8YbYhAtNCSWCxS83dpfpgPKD12BFk9vetxy6cA&oe=644737D1" className="d-block w-100" alt="..."/>
                            </div>
                            <div className="carousel-item">
                            <img classname="imgNosotrosCarousell" src="https://scontent.fslw1-1.fna.fbcdn.net/v/t39.30808-6/302017948_623323916032257_2970411986847589666_n.jpg?stp=cp6_dst-jpg&_nc_cat=105&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEVwIohK8qMKVUOKtHPgxiV5fLwViU_01Pl8vBWJT_TU4nZJ3U-ROWGCK03HetOtj4XqR29bLEOB9yMcZHxNOEw&_nc_ohc=d-wmirU1sKsAX9f1B5A&_nc_ht=scontent.fslw1-1.fna&oh=00_AfBg4CvY3EfwLUtBGxzanFXSgLyLNlN3T-XGtDg69qdqrA&oe=64481909" className="d-block w-100" alt="..."/>
                            </div>
                        </div>
                    </div>                   
                </div>
                <div className="row mt-5 MMV">
                        <div className="col-12 col-md-4">
                            <h3 className='tituloMMV'>Misión</h3>
                            <div className='separadorMMV mt-1 mb-3'></div>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur id beatae magni eaque, vero soluta optio. Optio tempora illo dolores. Ex eveniet sunt molestias voluptas nihil tempore voluptate repudiandae, dolore placeat, soluta autem saepe. Eveniet quo deleniti, delectus modi voluptatem 
                                laborum accusamus architecto cumque veritatis excepturi,
                                quisquam sed magni debitis.
                            </p>
                        </div>
                        <div className="col-12 col-md-4">
                            <h3 className='tituloMMV'>Vision</h3>
                            <div className='separadorMMV mt-1 mb-3'></div>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur id beatae magni eaque, vero soluta optio. Optio tempora illo dolores. Ex eveniet sunt molestias voluptas nihil tempore voluptate repudiandae, dolore placeat, soluta autem saepe. Eveniet quo deleniti, delectus modi voluptatem 
                                laborum accusamus architecto cumque veritatis excepturi,
                                quisquam sed magni debitis.
                            </p>
                        </div>
                        <div className="col-12 col-md-4">
                            <h3 className='tituloMMV'>Valores</h3>
                            <div className='separadorMMV mt-1 mb-3'></div>
                                <ul>
                                    <li>Lorem.</li>
                                    <li>Lorem, ipsum.</li>
                                    <li>Lorem.</li>
                                </ul>
                        </div>
                    </div>
            </div>         
        </div>
    )
}
