import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const ServiciosScreenAll = () => {

    const [servicios, setServicios] = useState()

    const getServicios = async(setServicios)=>{
        const {data} = await axios.get('https://apipwx.ovh/api/services?activo=true')
        setServicios(data.servicios)    
    }

    useEffect(()=>{
        getServicios(setServicios)
    },[])
    return (
        <div className='ServiciosScreenContainer'>
            <div className='tituloContainerServicios'>
                <h1 className='titulo Servicios'>Nuestros Servicios</h1>
            </div>
            <div className='tituloContainerServicios'>
                {servicios?.map(servicio => {
                    return (
                        <div className='CardServicioScreen' key={servicio._id}>
                            <div className='ImgCardServicio'>
                                <img src={servicio.miniatura} alt={servicio.nombre}/>
                            </div>
                            <div className='textoServicioCard'>
                                <h3>{servicio.nombre}</h3>
                                <p dangerouslySetInnerHTML={{__html: servicio.description.substr(0,300)}}></p>
                                <Link to={`/Servicio/${servicio._id}`} className="learn-more btn-gral mt-5">
                                    <span className="circle" aria-hidden="true">
                                    <span className="icon arrow"></span>
                                    </span>
                                    <span className="button-text">Más Info</span>
                                </Link>  

                            </div>
                        </div>
                    )

                })}

            </div>

        </div>
    )
}
