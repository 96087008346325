import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


export const ServiciosHome = () => {


  const [servicios, setServicios] = useState()

  const getServicios = async(setServicios)=>{
    const {data} = await axios.get('https://apipwx.ovh/api/services?activo=true&limite=3')
    setServicios(data.servicios)    
  }

  useEffect(()=>{
    getServicios(setServicios)
  },[])

  return (
    <div className='containerServicio mt-5'>
      <div className='row serviceRow'>
        <div className='col-12 titulo-Servicios'>
          <h3>Servicios</h3>
        </div>        
      </div>

      <div className='row mt-5' >
        <div className='col-12 card-Servicios'>

          {servicios?.map(servicio => {
            return( 
              <div className='cardServicio' key={servicio._id}>
              <img  className="imgServicioMin" src={servicio.miniatura} alt={servicio.nombre}/>   
              <div className='tituloServicio'>
                <Link to={`/Servicio/${servicio._id}`} className='tituloServicio'>{servicio.nombre}</Link>
              </div>
            </div>  
            )
          })}          
        </div>          
      </div>


          <Link to="/Servicios" className="learn-more btn-gral mt-5">
              <span className="circle" aria-hidden="true">
              <span className="icon arrow"></span>
              </span>
              <span className="button-text">Ver todos</span>
          </Link>  

      <div className='serparador-Servicios'></div>

        
      
    </div>
  )
}
