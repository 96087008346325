import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <div className='footerContainer'>
            <div className="btnContactoHoome col-12 col-md-4">
                <Link className='btnContactoHome' to="/Contacto" >
                    <span>Contacto</span>
                    <div className="top"></div>
                    <div className="left"></div>
                    <div className="bottom"></div>
                    <div className="right"></div>
                </Link>
            </div>
            <div className="datosContactoHome col-12 col-md-4">
                <ul className='listDireccionHome'>
                    <li><h5>#ProworxBy420</h5></li>
                    <li>Calle #123</li>
                    <li>Saltillo,Coahuila,México,25020</li>
                    <li>+528441231234</li>
                </ul>
                <ul className='listRedesFooter'>
                    <li><a href='https://google.com.mx' target="_blank" rel='noreferrer'><img className='redConactcFooter' src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1678360112/imagenes/Miscelaneos/Home/faceColor_qiknjr.png" alt="Facebook" /></a></li>
                    <li><a href='https://google.com.mx' target="_blank" rel='noreferrer'><img className='redConactcFooter' src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1678360112/imagenes/Miscelaneos/Home/instaColor_wpdysb.png" alt="instagram" /></a></li>
                    <li><a href='https://google.com.mx' target="_blank" rel='noreferrer'><img className='redConactcFooter' src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1678360111/imagenes/Miscelaneos/Home/youtubeColor_swonr5.png" alt="youtube" /></a></li>
                </ul>
            </div>
            
        </div>
    )
}
