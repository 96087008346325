import React from 'react'
import { Link } from 'react-router-dom'

export const NosotrosHome = () => {
    return (
        <div className='container-fluid contenedor-nosotros'>
            <div className='row'>
                <div className='col-12 col-md-2  cont-titulo'>
                    <h2 className='tituloNosotros'>NOSOTROS</h2> 
                </div>
                <div className='col-12 col-md-5 cont-texto'>
                    <div className='col-12'>
                        <p> Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Inventore ipsum aperiam esse dolorem placeat autem necessitatibus? Dicta distinctio quo minus voluptatem quasi consequuntur reiciendis,
                            ad minima sunt, cupiditate, ea dolor cumque similique delectus aut quod ullam autem at suscipit ratione numquam commodi velit soluta dolorem.
                            Earum, vero aperiam! Laudantium adipisci aliquid modi! Fuga voluptates aut exercitationem nisi excepturi quod, error aliquam nihil. 
                            Ullam magnam modi officia aperiam, totam deserunt? Quia porro, eum natus asperiores neque provident minima esse, odio dicta nihil saepe 
                            expedita minus quo architecto aut optio voluptatem reprehenderit tempore odit perspiciatis dignissimos alias voluptas aliquam dolore!
                            Doloribus aperiam voluptates hic vitae impedit accusamus esse architecto quas quasi minima expedita earum ad ullam ex accusantium, 
                            sit, debitis ipsa? Sequi quia fuga quod ipsa est quasi sunt, nostrum repellendus cum vitae, impedit placeat harum suscipit nihil asperiores
                            distinctio consectetur explicabo. Maiores culpa sint ipsa dolorem repellat fuga placeat, a libero?
                        </p>                 

                    </div>
                    <div className='containerBotonNosotros'>
                                        <Link className="learn-more btn-gral" to="/SobreNosotros">
                                            <span className="circle" aria-hidden="true">
                                            <span className="icon arrow"></span>
                                            </span>
                                            <span className="button-text">Ver más</span>
                                        </Link>
                    </div>
                </div>
                <div className='col-12 col-md-5 cont-texto'>
                    <div className='row rowSeparador'>
                        <div className='separador'></div>
                    </div>
                    <div className='row rowImahen mt-4'>
                        <img  className='imgNosotros' src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1677701614/imagenes/Miscelaneos/Nosotros/Social_Square-2021-rzr-trail-900-premium-blue_SIX6568_09626-800x534_pwcoan.webp" alt="nosotros Proworx" />
                    </div>

                </div>
            </div>
        </div>
    )
}
