import React from 'react'

export const BtnWhats = () => {
    return (
        <div className='whatsappContainer'>
            <a href="https://api.whatsapp.com/send?phone=528445414531&text=Hola,%20Necesito%20más%20información%20sobre%20sus%20servicios%20por%20favor." target="_blank" rel='noreferrer'>
                <img  className="WhatsAppIconImage" src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1678360111/imagenes/Miscelaneos/Home/whatsapp_fpfg9l.png" alt="proworx whatsapp" />
            </a>
            
        </div>
    )
}
