import React from 'react'

export const VideoHome = () => {
return (
    <div className='containerVideo'>
        <div className="coverVideo col-md-12">        
            <video className='videoHome' autoPlay muted loop>
                <source src="https://res.cloudinary.com/dyikrt3cc/video/upload/v1677702205/videos/Miscelaneos/Home/Can-am_Maverick_X3_orvbxz.mp4" type="video/mp4"/>
            </video>       
        </div>
        <div className='fraseContainer'>
        <figure>
            <blockquote className="blockquote">
                <p>Muéstrame cómo conduces y te mostraré quién eres.</p>
            </blockquote>
            <figcaption className="blockquote-footer">
                Dominic Toretto, <cite title="Source Title">Rápido y furioso</cite>
            </figcaption>
            </figure>
        </div>
        
    </div>
)
}
