import React from 'react'
import { Link } from 'react-router-dom'



export const Menu = () => {
    return (
        <nav className='menuNavegacion sticky-top'>
            <div className='rowRedes'>
                <div className='menuRedes'>
                    <a className='linkRedes' href='https://www.facebook.com/'><img className="logosRedes" src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1678360111/imagenes/Miscelaneos/Home/facebook_zgiw7p.png" alt="facebook" /></a>
                    <a className='linkRedes' href='https://www.instagram.com/'><img className="logosRedes" src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1678360111/imagenes/Miscelaneos/Home/instagram_bxj6ak.png" alt="Instagram" /></a>
                    <a className='linkRedes' href='https://www.youtube.com/'><img className="logosRedes" src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1678360111/imagenes/Miscelaneos/Home/youtube_ztzzmg.png" alt="youtube" /></a>
                </div>
            </div>
            <div className='row MenuApp d-flex row'>
                <div className='logo col-6'>
                    <Link to='/'><img className='logoMenu' src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1673286079/imagenes/Miscelaneos/Home/logo_u6cmbw.webp" alt="Proworx by 420" /></Link>
                </div>
                <div className='menuOfCanvas col-6'>
                    <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offCanvasMenu" aria-controls="offCanvasMenu">                   
                        <span className="material-symbols-outlined iconoMenu">
                            menu
                        </span>
                    </button>

                    <div className="offcanvas offcanvas-end canvas" tabIndex="-1" id="offCanvasMenu" aria-labelledby="offCanvasMenuLabel">
                        <div className="offcanvas-header">
                            <button type="button" className="btn-close btn-cerrar" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className='ulMenu'>
                                <Link className='linkMenu' to="/">  <li className='liMenu'>Inicio </li></Link>
                                <Link className='linkMenu' to="/SobreNosotros">  <li className='liMenu'>Sobre Nosotros </li></Link>
                                <Link className='linkMenu' to="/Servicios">  <li className='liMenu'>Servicios </li></Link>
                                <Link className='linkMenu' to="/Contacto">  <li className='liMenu'>Contacto </li></Link>
                            </ul>
                        </div>
                        </div>
                </div>
            </div>    
                    
        </nav>
    )
}
