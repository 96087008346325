import React, { useState } from 'react'

export const Contacto = () => {

    const [contactoUsuario, setContactoUsuario] = useState({
        nombre  : "",
        email  : "",
        telefono: "",
        asunto  : "",
        mensaje : ""
    })

    const handleDataForm = (e)=>{
        setContactoUsuario({...contactoUsuario,[e.target.name]:e.target.value})
    }

    const handleEnvioMensaje = ()=>{      
        const mensaje = `Hola soy ${contactoUsuario.nombre} me comunico para lo siguiente: ${contactoUsuario.mensaje} espero información por este medio o por el correo ${contactoUsuario.email}`
        window.open(`https://api.whatsapp.com/send?phone=528445414531&text=${mensaje}`,)
        
    }


    return (
        <div className="container-fluid container-contacto p-5">
            <div className="row mt-5">
                <h2><b>CONTACTO</b></h2>
            </div>
            <div className="row">
                <div className='col-12 col-md-6 formContacto'>

                    <div class="mb-3">
                            <label for="nombre" className="form-label">Nombre Completo</label>
                            <input onChange={handleDataForm} type="text" className="form-control" id="nombre" name='nombre' aria-describedby="NombreCompleto"/>
                        </div>
                        <div class="mb-3">
                            <label for="email" className="form-label">Email</label>
                            <input onChange={handleDataForm} type="email" class="form-control" id="email" name='email'/>
                        </div>
                        <div class="mb-3">
                            <label for="telefono" className="form-label">telefono</label>
                            <input onChange={handleDataForm} type="text" className="form-control" id="telefono" name='telefono'/>
                        </div>
                        {/* <div class="mb-3">
                            <label for="asunto" className="form-label">asunto</label>
                            <input onChange={handleDataForm} type="text" className="form-control" id="asunto" name='asunto'/>
                        </div> */}
                        <label for="floatingTextarea">Mensaje</label>
                        <textarea onChange={handleDataForm} className="form-control textareaContacto" placeholder="Por favor ingresa tu mensaje aqui" id="floatingTextarea" name='mensaje'></textarea>
                        <button onClick={handleEnvioMensaje} className="btn btn-primary mt-4">Enviar</button>                    
                </div>  
                <div className="col-12 col-md-6">
                    <div>
                        <img className="ms-5 mt-5 imgContacto" src='https://i.pinimg.com/originals/a7/3d/6e/a73d6e9c014cef5372c6447d25988e7d.gif' alt='...'/>
                    </div>
                </div>
                        
            </div>
            <div className="row mt-5">
                <div className="col-12">
                                <iframe 
                                    title='Map'
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.2291051334846!2d-100.97374702385818!3d25.43060292220591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868812af48a6cba3%3A0x905937edd8896036!2sServiauto%20420!5e0!3m2!1ses-419!2smx!4v1682096720544!5m2!1ses-419!2smx"
                                    width="100%" 
                                    height="450" 
                                    style={{border:0}}
                                    allowfullscreen=""
                                    loading="lazy" 
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>          

                            
                </div>
            </div>
        </div>
    )
}
