import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { BtnWhats } from '../components/elementos/BtnWhats'
import { Footer } from '../components/elementos/Footer'
import { Menu } from '../components/elementos/Menu'
import {HomeScreen} from '../components/home/HomeScreen'
import { ServicioDetalle } from '../components/services/ServicioDetalle'
import { ServiciosScreenAll } from '../components/services/ServiciosScreenAll'
import { SobreNosotros } from '../components/about/SobreNosotros'
import { Contacto } from '../components/contacto/Contacto'

export const AppRouter = () => {
    return (
        <>
            <Menu/>
            <Routes>
                <Route path='/' element={<HomeScreen/>} />
                <Route path='/Servicios' element={<ServiciosScreenAll/>} />
                <Route path='/Servicio/:id' element={<ServicioDetalle/>} />
                <Route path='/SobreNosotros' element={<SobreNosotros/>} />
                <Route path='/Contacto' element={<Contacto/>} />
                <Route path='*' element={<HomeScreen/>}/>
            </Routes>
            <Footer/>
            <BtnWhats/>
            
        </>
    )
}
