
import React, { useState } from 'react'

export const GaleriaImagenes = ({imagenes}) => {

    const [currentIndex, setCurrentIndex] = useState(0);
        
    const handleClick = (e) => {
        const idBuscado = e.target.name
        console.log(idBuscado)
        // eslint-disable-next-line 
        imagenes?.map((imagenes, index)=>{            
            if(imagenes?._id === idBuscado) setCurrentIndex(index)        
        })
        
        
    };
        
    return (       

        
        <div className='galeriaContainer'>
            {imagenes ?
                <div className='ContenedorImagenes'>

                
                <div className='imagenPrincipalContainer'>
                <div className="thumbnails">
                        {imagenes?.map((imagen, index) => (
                            <img
                                key={index}
                                src={imagen?.url}
                                name={imagen?._id}
                                alt={imagen?.nombre}
                                className={currentIndex === index ? 'active' : ''}
                                onClick={(e) => handleClick(e)}
                            />
                            ))}
                    </div>
                    <img className="ImagenPrincipal" src={imagenes[currentIndex]?.url} alt={imagenes[currentIndex]?.nombre}/>
                </div>               

                </div>               

                :

                <div className='imagenPrincipal'>
                    <p>{"nada"}</p>
                </div>

            
        }
            
        </div>
    
    )
}
