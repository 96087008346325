import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { GaleriaImagenes } from './GaleriaImagenes'



export const ServicioDetalle = () => {

    const {id} = useParams()

    const [servicio , setServicio] = useState({})

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    

    useEffect(()=>{
            async function getData(){
                const {data} = await axios.get(`https://apipwx.ovh/api/services/${id}`)
                setServicio(data.servicio)
            }
            getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    




    return (
        <div className='containerServicioDetalle'>
            <div className='videoDetalleContainer'>
                
            {servicio.video && (
                <video className='videoDetalle' autoPlay muted loop>
                    <source src={servicio.video} type="video/mp4"/>
                </video>
            )}
                <div className="loader"></div>                

            </div>
            <div className='row'>
                <div className='col-12 col-md-6 textoDetalle'>
                    <h2>{servicio?.nombre}</h2>
                    <p dangerouslySetInnerHTML={{__html: servicio.description}}></p>
                </div>
                <div className='col-12 col-md-6 btnContactanosDetalle'>
                    <Link to="/Contacto" className="learn-more btn-gral">
                        <span className="circle" aria-hidden="true">
                        <span className="icon arrow"></span>
                        </span>
                        <span className="button-text">Contacto</span>
                    </Link>
                </div>
            </div>
            <div className='row'>
                <div className='GaleríaTitle mt-5 mb-5'>
                    <h2>Galería</h2>                   
                </div>
                <GaleriaImagenes imagenes={servicio?.images} />
            </div>
            <div className='serparador-Servicios'></div>

        </div>
    )
}
