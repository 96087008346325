import React from 'react'
import { NosotrosHome } from './NosotrosHome'
import { ServiciosHome } from './ServiciosHome'
import { Slider } from './Slider'
import { VideoHome } from './VideoHome'

export const HomeScreen = () => {
return (
    <div>
        
        <Slider/>
        <NosotrosHome/>
        <VideoHome/>
        <ServiciosHome/>
    </div>
)
}
