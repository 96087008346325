import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import axios from "axios"

export const Slider = () => {

  const [banners , setBanners] = useState()

  useEffect(()=>{
      getBanners(setBanners)
    },[])

  const getBanners = async(setBanners)=>{
    const {data} = await axios.get('https://apipwx.ovh/api/banners')
    setBanners(data.banners) 
  }


  return (
    <>
    <div className='contenedorSlider'>
    <Carousel className='Slider' controls={false}>      
      {banners?.map(banner => {
        return(
          <Carousel.Item key={banner._id}>
            <img className='imgBanner' src={banner.url} alt={banner.titulo} />
          </Carousel.Item>
        )
      })}
    </Carousel>
    
    </div>
    
    </>
  )

}
